import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    content: String,
    url: String,
    use: String, // "small" (ZD411) or "large" (ZD421)
    amount: Boolean,
    fileToClientFirst: Boolean // 👈 NEW: Determines whether to fetch PDFs first
  }

  static targets = ['device', 'content', 'go', 'param']

  connect() {
    this.maxWidth = {
      "small": 420,
      "large": 780
    };
    this.originalContent = this.contentValue;
    this.amount = 1;
    this.errors = {
      printServer: "Labels kunnen niet worden geprint. Controleer de printserver.",
      noPrinter: "Geen printer opgegeven.",
      noContent: "Geen tekst of URL opgegeven voor het label.",
      invalidAmount: "Geen geldig aantal opgegeven.",
      sendError: "Fout bij het verzenden naar de printer."
    };

    this.goTarget.disabled = false; // Enable button immediately
  }

  now(e) {
    e.preventDefault();

    if (this.amountValue) {
      this.amount = prompt("Hoeveel labels moeten er geprint worden?", 1);
    }

    if (this.amount == null || this.amount === "") return false; // Cancelled
    if (isNaN(parseInt(this.amount))) return alert(this.errors.invalidAmount);

    const printer = this.useValue;
    if (!printer) return alert(this.errors.noPrinter);

    if (!this.hasContentValue && !this.hasUrlValue) return alert(this.errors.noContent);
    if (this.contentValue === '' && this.urlValue === '') return alert(this.errors.noContent);

    for (let i = 0; i < this.amount; i++) {
      if (this.hasContentValue) {
        this.setContent();
        this.sendPrintRequest({ printer, zpl: this.contentValue });
      }

      if (this.hasUrlValue) {
        let url = this.urlValue;
        if (this.hasParamTarget) url += `?${this.paramTarget.name}=${this.paramTarget.value}`;

        if (this.fileToClientFirstValue) {
          this.fetchAndSendPDF(url, printer);
        } else {
          this.sendPrintRequest({ printer, url });
        }
      }
    }
  }

  /**
   * Fetch the PDF first if `file-to-client-first` is enabled, then send to the print server.
   */
  fetchAndSendPDF(url, printer) {
    fetch(url, { credentials: "include" }) // 👈 Ensure authentication cookies are included
      .then(response => {
        if (!response.ok) throw new Error("Kon PDF niet ophalen");
        return response.blob();
      })
      .then(blob => {
        const formData = new FormData();
        formData.append("printer", printer);
        formData.append("file", blob, "label.pdf");

        return fetch("https://dectdirect.synology.me:5050/print", {
          method: "POST",
          body: formData
        });
      })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          alert(`${this.errors.sendError} (${data.error})`);
        } else {
          console.log("✅ Authenticated PDF print request sent:", data);
        }
      })
      .catch(error => {
        alert(this.errors.sendError);
        console.error("Fetch & Print Error:", error);
      });
  }

  sendPrintRequest(payload) {
    fetch("https://dectdirect.synology.me:5050/print", {
      method: "POST",
      body: new URLSearchParams(payload),
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          alert(`${this.errors.sendError} (${data.error})`);
        } else {
          console.log("✅ Print request sent:", data);
        }
      })
      .catch(error => {
        alert(this.errors.sendError);
        console.error("Print error:", error);
      });
  }

  setContent() {
    if (this.hasContentTarget) {
      this.contentValue = this.originalContent.replace('%YIELD%', this.contentTarget.value.replaceAll("\n", "\\&"));
      this.contentValue = this.contentValue.replace('%MAXWIDTH%', this.maxWidth[this.useValue]);
    }
  }

  setDevice() {
    if (this.hasDeviceTarget) this.useValue = this.deviceTarget.value;
    this.setContent();
  }

  setUrl(e) {
    this.urlValue = e.currentTarget.value;
  }
}
