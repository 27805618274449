import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    url: String,               // Remote URL for fetching options (expects ?q=...)
    minChars: { type: Number, default: 0 },
  };

  connect() {
    // Default options for Tom Select with remote loading.
    this.tomSelect = new TomSelect(this.element, {
      placeholder: 'Zoek orders, klanten, offertes, tickets of producten',
      searchField: ['text'],
      optgroupField: 'optgroup', // Use the 'optgroup' property in your options
      // We'll use the load callback to fetch data remotely.
      load: async (query, callback) => {
        try {
          const response = await fetch(`${this.urlValue}?q=${encodeURIComponent(query)}`);
          if (!response.ok) throw new Error(`HTTP Error: ${response.status}`);
          const data = await response.json();

          // Compute unique optgroups from the data.
          const groups = new Set();
          data.forEach(item => {
            groups.add(item.optgroup || "Ungrouped");
          });

          // Add each unique optgroup to Tom Select.
          groups.forEach(group => {
            this.tomSelect.addOptionGroup(group, { label: group });
          });

          // Pass the formatted options to Tom Select.
          callback(data);
        } catch (error) {
          callback();
        }
      },
      // When an item is added, redirect via Turbo if the value starts with "/".
      onItemAdd: (value) => {
        if (value && value.startsWith("/")) {
          Turbo.visit(value);
        }
      }
    });
  }

  disconnect() {
    this.tomSelect.destroy();
  }
}
