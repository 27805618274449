import { Controller } from "@hotwired/stimulus";
import { install, uninstall } from "@github/hotkey"; // Ensure you've installed this library

/**
 * Example:
 * <button data-controller="hotkey" data-hotkey-use-value="s" onclick="window.alert('yes')">Alert</button>
 */

export default class extends Controller {
  static values = {
    use: String
  };

  connect() {
    install(this.element, this.useValue);
  }

  disconnect() {
    uninstall(this.element);
  }
}
